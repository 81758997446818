.content{
  align-items: center;
  flex-direction: column;
  display: flex;
  background-color: white;
  min-height: 100vh;
  padding: 20px;
  padding-top: 70px;
}

.logo{
  max-width: 70%;
  max-height: 150px;
}

.formContainer{
  width: 100%;
}

.textContainer{
  text-align: center;
}

.cardfield{
  height: 100px;
}

.buttonContainer { 
  display: flex;
  margin-top: 50px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.submitButton{
  margin: auto;
}

.form_container {
  width: 400px;
  padding: 50px;
  background-color: white;
  margin: auto;
  margin-bottom: 15px;
}

.para {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.form_container .title {
  font-size: 21px;
  font-weight: bold;
  letter-spacing: 3px;
}

.form_fields {
  margin-top: 35px;
}

.form_fields .input_container,
.textarea_container {
  margin-bottom: 15px;
}

.form_fields .input_container:last-child {
  margin-bottom: 0;
}

.form_fields .input_group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form_fields .input_group input {
  width: 100%;
  margin-bottom: 10px;
}

.form_fields input,
textarea {
  width: 100%;
  border: none;
  border-radius: 6px;
  height: 50px;
  outline: none;
  background-color: #f2f2f2;
  font-size: 14px;
  text-indent: 10px;
}

.form_fields input:focus,
textarea:focus {
  background-color: #eae8e8;
  transform: scale(1.01, 1.01);
}

.submitSpan{
  padding         : 15px 50px;
  font-size       : 0.9em;
  font-weight     : 300 ;
  color           : #fff;
  background-color: #250F3A;
  border-radius   : 20px;
  height: 3rem;
  width: 100%;
  text-decoration: none;
  text-align: center;
}

 .submitSpan:hover {
  background-color: rgb(37, 15, 58, 0.9);
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.19);
}

 .submit {
  display: flex;
  justify-content: center;
  align-items: center;

}

.poweredByContainer{
  display: flex;
  margin-top: 30px;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .content{
    align-items: center;
    background-color: white;
  }

  .form_fields .input_group {
    margin-bottom: 20px;
  }
} 
